import { Action, Mutation } from 'vuex-module-decorators';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { Resolve } from 'vue-di';
import { ClaimV1Api, EdgeEnrollmentApi } from '@trialcard/apigateway.client';
import {
    EnrollmentIsValidMemberNumberRequestModel,
    EnrollmentCheckMemberNumberDefaultResponse,
    ClaimAccumulatorModels,
} from '@trialcard/apigateway.models';
import { AxiosResponse } from 'axios';
import { Settings } from '~/services/settings';

@InjectModule({ stateFactory: true }, module)
export default class ActivationModule extends InjectVuexModule {
    @Resolve
    public claimApi!: ClaimV1Api;

    @Resolve
    public edgeEnrollmentApi!: EdgeEnrollmentApi;

    @Resolve
    public settings!: Settings;

    public memberNumber = '';
    public errorMessage = ''
    public apiError = false;
    public isInvalidError = false;
    public isActiveError = false;

    @Mutation
    updateMemberNumber(newMemberNumber: string) {
        this.memberNumber = newMemberNumber;
    }

    @Action({})
    checkAccumulator(memberNumber: string): Promise<AxiosResponse<ClaimAccumulatorModels>> {
        return this.claimApi.getByMemberNumber(memberNumber, undefined);
    }


    @Mutation
    public clearError() {
        this.errorMessage = '';
    }
    @Mutation
    setIsInvalidError(){
        this.apiError = true;
    }
    @Mutation
    setisActiveError(){
        this.isActiveError = true;
    }
    @Mutation
    SetapiError(){
        this.apiError = true;
    }
    @Action({ rawError: true })
    async shouldActivateMemberNumber(memNum: string) {
        try {
            const memNumData = (await this.checkMemberNumber(memNum)).data || null;
            if (JSON.stringify(memNumData) === 'null') {
                this.SetapiError()
                return false

            } else if (memNumData.data && memNumData.data.validationMessage === "Valid Membernumber") {
                this.updateMemberNumber(memNum);
                return true
            }
            else if (memNumData.data && memNumData.data.validationMessage === "MemberNumber already in use") {
                this.setisActiveError();
                return false

            } else if((memNumData.data && memNumData.data.validationMessage && memNumData.data.validationMessage.includes("Membernumber doesn't exists!")) || (memNumData.data && memNumData.data.validationMessage && memNumData.data.validationMessage.includes("Membernumber doesn't belong to Program Id"))) {
                this.setIsInvalidError();
                return false
            }
        } catch (error) {
            this.setisActiveError()
            return false
        }
        return ! (this.apiError ||  this.isInvalidError  ||  this.isActiveError)
    }

    @Action({})
    checkMemberNumber(memberNumber: string): Promise<AxiosResponse<EnrollmentCheckMemberNumberDefaultResponse>> {
        const requestModel: EnrollmentIsValidMemberNumberRequestModel = {
            memberNumber,
            haveACard: true,
            partyId: 1, // TODO: ask about this
            checkForEnrollment: true,
        };
        return this.edgeEnrollmentApi.enrollmentCheckMemberNumber(requestModel, this.settings.programId);
    }
}
