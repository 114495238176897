import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6de6bc62 = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages_error" */))
const _76cd7df0 = () => interopDefault(import('..\\pages\\have-a-card.vue' /* webpackChunkName: "pages_have-a-card" */))
const _09f14b0c = () => interopDefault(import('..\\pages\\have-a-card\\index.vue' /* webpackChunkName: "pages_have-a-card_index" */))
const _648873b5 = () => interopDefault(import('..\\pages\\have-a-card\\eligibility.vue' /* webpackChunkName: "pages_have-a-card_eligibility" */))
const _fef174e8 = () => interopDefault(import('..\\pages\\have-a-card\\enrollment.vue' /* webpackChunkName: "pages_have-a-card_enrollment" */))
const _484e61e8 = () => interopDefault(import('..\\pages\\have-a-card\\patient-information.vue' /* webpackChunkName: "pages_have-a-card_patient-information" */))
const _0c3aa5aa = () => interopDefault(import('..\\pages\\have-a-card\\success.vue' /* webpackChunkName: "pages_have-a-card_success" */))
const _5fe37860 = () => interopDefault(import('..\\pages\\rejection.vue' /* webpackChunkName: "pages_rejection" */))
const _732fd6e6 = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _c15e0e26 = () => interopDefault(import('..\\pages\\upload-documents\\index.vue' /* webpackChunkName: "pages_upload-documents_index" */))
const _39e70130 = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _39f518b1 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _3a1147b3 = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _3a1f5f34 = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _6ecd3c71 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _4b1410e3 = () => interopDefault(import('..\\pages\\need-a-card\\eligibility.vue' /* webpackChunkName: "pages_need-a-card_eligibility" */))
const _bf09c5a0 = () => interopDefault(import('..\\pages\\need-a-card\\insurance.vue' /* webpackChunkName: "pages_need-a-card_insurance" */))
const _05c80ee2 = () => interopDefault(import('..\\pages\\need-a-card\\insurance-information.vue' /* webpackChunkName: "pages_need-a-card_insurance-information" */))
const _b879438c = () => interopDefault(import('..\\pages\\need-a-card\\patient-information.vue' /* webpackChunkName: "pages_need-a-card_patient-information" */))
const _54df5e9e = () => interopDefault(import('..\\pages\\need-a-card\\prescriber-information.vue' /* webpackChunkName: "pages_need-a-card_prescriber-information" */))
const _a95fa1a8 = () => interopDefault(import('..\\pages\\need-a-card\\select-path.vue' /* webpackChunkName: "pages_need-a-card_select-path" */))
const _0f381159 = () => interopDefault(import('..\\pages\\need-a-card\\success.vue' /* webpackChunkName: "pages_need-a-card_success" */))
const _11e2d59e = () => interopDefault(import('..\\pages\\patient\\patientSearch.vue' /* webpackChunkName: "pages_patient_patientSearch" */))
const _f572f8ce = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/error",
    component: _6de6bc62,
    name: "error"
  }, {
    path: "/have-a-card",
    component: _76cd7df0,
    children: [{
      path: "",
      component: _09f14b0c,
      name: "have-a-card"
    }, {
      path: "eligibility",
      component: _648873b5,
      name: "have-a-card-eligibility"
    }, {
      path: "enrollment",
      component: _fef174e8,
      name: "have-a-card-enrollment"
    }, {
      path: "patient-information",
      component: _484e61e8,
      name: "have-a-card-patient-information"
    }, {
      path: "success",
      component: _0c3aa5aa,
      name: "have-a-card-success"
    }]
  }, {
    path: "/rejection",
    component: _5fe37860,
    name: "rejection"
  }, {
    path: "/unlock",
    component: _732fd6e6,
    name: "unlock"
  }, {
    path: "/upload-documents",
    component: _c15e0e26,
    name: "upload-documents"
  }, {
    path: "/errors/400",
    component: _39e70130,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _39f518b1,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _3a1147b3,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _3a1f5f34,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _6ecd3c71,
    name: "errors-500"
  }, {
    path: "/need-a-card/eligibility",
    component: _4b1410e3,
    name: "need-a-card-eligibility"
  }, {
    path: "/need-a-card/insurance",
    component: _bf09c5a0,
    name: "need-a-card-insurance"
  }, {
    path: "/need-a-card/insurance-information",
    component: _05c80ee2,
    name: "need-a-card-insurance-information"
  }, {
    path: "/need-a-card/patient-information",
    component: _b879438c,
    name: "need-a-card-patient-information"
  }, {
    path: "/need-a-card/prescriber-information",
    component: _54df5e9e,
    name: "need-a-card-prescriber-information"
  }, {
    path: "/need-a-card/select-path",
    component: _a95fa1a8,
    name: "need-a-card-select-path"
  }, {
    path: "/need-a-card/success",
    component: _0f381159,
    name: "need-a-card-success"
  }, {
    path: "/patient/patientSearch",
    component: _11e2d59e,
    name: "patient-patientSearch"
  }, {
    path: "/",
    component: _f572f8ce,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
