// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".safety-info-header{color:#0086a1!important;padding:.5rem!important;font-family:Carlito Regular;font-size:16px!important;line-height:unset!important}.scroll-content{overflow-y:scroll;height:300px;padding:1rem}.scroll-content::-webkit-scrollbar{width:5px;background-color:#e5e0e0}.scroll-content::-webkit-scrollbar-thumb{background-color:#90aaa4;border-radius:5px}.isi-content h4{color:var(--color-secondary);font-weight:700}.isi-content .iri-section{padding:0 0 1em}.isi-content .iri-section:first-of-type .inner-iri-section{border-bottom:1px solid #ababab;padding-bottom:1em}.isi-content h3{font-size:1.25em;font-weight:400;color:var(--color-secondary)}.isi-content h4{font-size:1.15em}.isi-content .highlight{font-size:1.2em;font-weight:700}.isi-content a{color:#0086a1!important;text-decoration:underline!important;font-weight:600;font-family:HelveticaNeueW01-80Bold,sans-serif}.border-box{border-width:3px;padding:16px;margin-bottom:16px;box-shadow:0 0 0 4px rgba(125,225,222,.5)}.heading{color:var(--color-secondaryBright)!important}li{line-height:2em}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#64b5f6",
	"secondaryColor": "#003c60",
	"accentColor": "#c41230"
};
module.exports = exports;
